
import {
  Component, Prop, Vue,
} from "vue-property-decorator";
import {
  EntityPermissionAction,
  FilePermission,
} from "@/model/team-role";
import {
  TEAM_ROLES_FILE_ACTION_CREATE,
  TEAM_ROLES_FILE_ACTION_DELETE,
  TEAM_ROLES_FILE_ACTION_READ,
  TEAM_ROLES_FILE_ACTION_UPDATE,
  TEAM_ROLES_FILE_ACTION_NONE,
} from "@/utils/team-role";

@Component({})
export default class FilePermissionsChip extends Vue {
  @Prop()
  pFilePermission!: FilePermission

  get sortedEntityActions() {
    return [...this.pFilePermission.actions].sort((a, b) => {
      if (a == TEAM_ROLES_FILE_ACTION_DELETE) return 1;
      if (b == TEAM_ROLES_FILE_ACTION_DELETE) return -1;
      if (a == TEAM_ROLES_FILE_ACTION_NONE) return -1;
      if (b == TEAM_ROLES_FILE_ACTION_NONE) return 1;

      return a.text.localeCompare(b.text);
    });
  }

  getActionColor(permission: EntityPermissionAction) {
    switch (permission) {
      case TEAM_ROLES_FILE_ACTION_CREATE:
        return ["green", "white--text"];
      case TEAM_ROLES_FILE_ACTION_READ:
        return ["cyan", "white--text"];
      case TEAM_ROLES_FILE_ACTION_UPDATE:
        return ["orange", "white--text"];
      case TEAM_ROLES_FILE_ACTION_DELETE:
        return ["red", "white--text"];
      case TEAM_ROLES_FILE_ACTION_NONE:
        return ["black", "white--text"];
      default:
        return "default";
    }
  }
}
